import { Injectable } from '@angular/core';
import {BehaviorSubject, delay} from "rxjs";

export class SnackBarParam{
  title: string;
  text: string;
  btnIcon: string;
  type: 'success' | 'error' | null;
  constructor(_title: string, _text: string, _type: 'success' | 'error') {
    this.title = _title;
    this.text = _text;
    this.type = _type;
    this.btnIcon = _type == 'success' ? 'ok_icon.svg' : 'not_ok_icon.svg'
  }
}



@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  isOpen = new BehaviorSubject<boolean>(false);
  param!: SnackBarParam;

  setOpen(open: boolean){
    this.isOpen.next(open);
  }

  alert(param: SnackBarParam){
    this.setOpen(true);
    this.param = param;

    setTimeout(() => {
      this.setOpen(false)
    }, 3500);
  }
}
